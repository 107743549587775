import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { lastSyncedAt: String };
  static targets = ["refreshTime"];

  constructor() {
    super(...arguments);
    this.lastSyncedTime = new Date();
  }

  connect() {
    this.refreshText(); // Call the initial text update
    this.startInterval(); // Start the interval for updating text
  }

  disconnect() {
    this.stopInterval(); // Stop the interval when the controller is disconnected
  }

  startInterval() {
    this.interval = setInterval(() => {
      this.refreshText();
    }, 60000); // Update every 2 seconds for testing purposes; change to 60000 for every minute
  }

  stopInterval() {
    clearInterval(this.interval);
  }

  refreshText() {
    const currentTime = new Date();
    const minutesDiff = Math.floor((currentTime - this.lastSyncedTime) / 60000);
    const refreshTimeTarget = this.refreshTimeTarget;

    let message;

    if (minutesDiff < 1) {
      message = "Last Synced a few seconds ago";
    } else if (minutesDiff < 60) {
      message = `Last Synced ${minutesDiff} minute${minutesDiff !== 1 ? 's' : ''} ago`;
    } else {
      const hoursDiff = Math.floor(minutesDiff / 60);
      message = `Last Synced ${hoursDiff} hour${hoursDiff !== 1 ? 's' : ''} ago`;
    }

    if (refreshTimeTarget) {
      refreshTimeTarget.textContent = message;
    }
  }

  refreshPage() {
    location.reload();
  }
}
