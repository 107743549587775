import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["editField"]

  enableEdits() {
    const editField = this.editFieldTarget;
    const wasDisabled = editField.disabled;

    setTimeout(() => {
      editField.disabled = !wasDisabled;
    }, 200);
  }
}
