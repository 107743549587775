import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bookings-search"
export default class extends Controller {
  static targets = ["input", "table", "counter"];
  
  connect() {
    console.log("Connected to bookings-search controller");
  }

  search() {
    const input = this.inputTarget;
    const filter = input.value.toUpperCase();
    const table = this.tableTarget;
    const tbody = table.getElementsByTagName("tbody")[0]; // Get the tbody element
    const tr = tbody.getElementsByTagName("tr"); // Get only the tr elements within tbody
    let visibleRowCount = 0;

    for (let i = 0; i < tr.length; i++) {
      let found = false;
      const td = tr[i].getElementsByTagName("td");
      for (let j = 0; j < td.length; j++) {
        const txtValue = td[j].textContent || td[j].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          found = true;
          break;
        }
      }
      if (found) {
        tr[i].style.display = "";
        visibleRowCount++;
      } else {
        tr[i].style.display = "none";
      }
    }
    this.counterTarget.innerText = `Showing ${visibleRowCount} bookings`;
  }
}
