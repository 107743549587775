import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sidebar"
export default class extends Controller {
  static targets = ["consentFormLink", "consultationFormLink", "insuranceFormLink", "containerInsurances", "containerConsentForms", "containerConsultationForms", "patientsLink", "containerPatients", "doctorsLink", "containerDoctors"]

  // connect() {
  //   console.log("test")
  // }

  updateSidebar(event) {
    const hideAllDivs = () => {
      containerInsurances.style.display = "none";
      containerConsentForms.style.display = "none";
      containerPatients.style.display = "none";
      containerDoctors.style.display = "none";
      containerConsultationForms.style.display = "none";
    };

    const changeBackgroundColor = () => {
      consentFormLink.style.backgroundColor = "#F2F2F2";
      consultationFormLink.style.backgroundColor = "#F2F2F2";
      insuranceFormLink.style.backgroundColor = "#F2F2F2";
      patientsLink.style.backgroundColor = "#F2F2F2";
      doctorsLink.style.backgroundColor = "#F2F2F2";
    };

    switch (event.target.id) {
      case "consentFormLink":
        hideAllDivs();
        changeBackgroundColor();
        containerConsentForms.style.display = "block";
        consentFormLink.style.backgroundColor = "#C9C9C9";
        break;
      case "insuranceFormLink":
        hideAllDivs();
        changeBackgroundColor();
        containerInsurances.style.display = "block";
        insuranceFormLink.style.backgroundColor = "#C9C9C9";
        break;
      case "patientsLink":
        hideAllDivs();
        changeBackgroundColor();
        containerPatients.style.display = "block";
        patientsLink.style.backgroundColor = "#C9C9C9";
        break;
      case "doctorsLink":
        hideAllDivs();
        changeBackgroundColor();
        containerDoctors.style.display = "block";
        doctorsLink.style.backgroundColor = "#C9C9C9";
        break;
      case "consultationFormLink":
        hideAllDivs();
        changeBackgroundColor();
        containerConsultationForms.style.display = "block";
        consultationFormLink.style.backgroundColor = "#C9C9C9";
        break;
      default:
        hideAllDivs();
        changeBackgroundColor();
        break;
    }
  }
}
