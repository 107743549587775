import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "searchField", "clear"];

  connect() {
    // Find the input field inside the div of this.inputTarget
    let input = this.inputTarget.querySelector("input");

    if (input) {
      // Add an event listener to the input field
      input.addEventListener("input", this.search.bind(this)); // Bind the context

      // Store the input element as a property of the controller
      this.inputElement = input;
    }

    // console.log(this.searchFieldTarget.value);
    if (this.searchFieldTarget.value.trim() !== "") {
      let divItem = this.inputTarget.querySelector(".ts-control .item");
      divItem.innerText = this.searchFieldTarget.value;
    }
    this.checkClearButton();
  }

  checkClearButton() {
    if (this.searchFieldTarget.value.trim() !== "") {
      this.clearTarget.classList.remove("d-none");
    } else {
      this.clearTarget.classList.add("d-none");
    }
  }

  updateField() {
    this.searchFieldTarget.value = this.inputElement.value;
  }

  search() {
    // console.log("searching");

    // Find the specific div element within the .ts-control div
    let divItem = this.inputTarget.querySelector(".ts-control .item");

    // Check if divItem is available before using it
    if (divItem && divItem.innerText.trim() !== "") {
      this.searchFieldTarget.value = divItem.innerText;
    } else {
      // call update field method
      if (this.inputElement.value.trim() !== "") {
        this.updateField();
      }
    }
  }

  clearSearch() {
    this.inputElement.value = "";
    this.searchFieldTarget.value = "";
  }
}
