// app/javascript/controllers/attendance_controller.js
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = [
        'attendanceDate',
        'attendanceEditDate',
        'doctorId',
        'doctorModalName',
        'doctorModalEditName',
        'doctorIdModal',
        'doctorIdEditModal',
        'doctorDateModal',
        'doctorDateEditModal',
        'doctorStatusModal',
        'doctorStatusEditModal',
        'attendanceEditValue',
        "doctorAttendanceIdEditModal"
    ]

    connect() {
        // console.log('Attendance controller connected')
    }

    clicked(event) {
        const classes = event.delegateTarget.classList
        const desiredClasses = [
            'attendance_day_after_tomorrow',
            'attendances_tomorrow',
            'attendances_today'
        ]

        // List of desired classes and corresponding date adjustments
        const classDateMapping = {
            attendance_day_after_tomorrow: {
                daysToAdd: 2,
                text: 'Add attendance for Day after tomorrow'
            },
            attendances_tomorrow: {
                daysToAdd: 1,
                text: 'Add attendance for Tomorrow'
            },
            attendances_today: {
                daysToAdd: 0,
                text: 'Add attendance for Today'
            }
        }

        // Find the class that matches any of the desired classes
        const desiredClass = Array.from(classes).find((cls) =>
            desiredClasses.includes(cls)
        )

        if (desiredClass) {
            // Get today's date
            const today = new Date()
            const daysToAdd = classDateMapping[desiredClass].daysToAdd
            const newDate = new Date(today.setDate(today.getDate() + daysToAdd))
            const utcDate = newDate.getTime() + (4 * 60 * 60 * 1000); // Adjust for GMT+4
            const finalDate = new Date(utcDate);

            // Format the new date to YYYY-MM-DD
            const formattedDate = finalDate.toISOString().split('T')[0];

            // Set the new date value to the date input
            const dateInput = this.doctorDateModalTarget
            if (dateInput) {
                dateInput.value = formattedDate
            }

            // Set the text for attendanceDateTarget
            this.attendanceDateTarget.innerText = classDateMapping[desiredClass].text
        }

        this.doctorModalNameTarget.innerHTML = event.target.dataset.name
        this.doctorIdModalTarget.value = event.target.id
    }

    clickedEdit(event) {
      // console.log(event.delegateTarget.classList)

      this.doctorModalEditNameTarget.innerHTML = event.delegateTarget.dataset.name;
      this.doctorIdEditModalTarget.value = event.delegateTarget.id
      this.doctorAttendanceIdEditModalTarget.value = event.delegateTarget.dataset.attendanceid;

    }
}
