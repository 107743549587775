import consumer from "./consumer";

let updateStatus;

consumer.subscriptions.create("StatusChannel", {
  connected() {
    // console.log("connected");
    updateStatus = () => this.liveUpdate(this.uninstall);
    this.install();
    window.addEventListener("turbo:load", () => this.liveUpdate())
  },

  disconnected() {
    // console.log("disconnected");
    this.uninstall();
  },

  rejected () {
    // console.log("Rejected");
    this.uninstall();
  },

  received(data) {
  },

  online () {
    // console.log("online");
    this.perform('online');
  },

  offline () {
    // console.log("offline");
    this.perform('offline');
  },

  uninstall () {
    // console.log("uninstall");
    const shouldRun = document.getElementById("status_channel");
    if (!shouldRun) {
      this.perform("offline");
    }
  },

  install () {
    // console.log("install")
    window.removeEventListener("load", updateStatus);
    window.removeEventListener("keydown", updateStatus);
    window.removeEventListener("DOMContentLoaded", updateStatus);
    window.removeEventListener("click", updateStatus);

    window.addEventListener("load", updateStatus);
    window.addEventListener("keydown", updateStatus);
    window.addEventListener("DOMContentLoaded", updateStatus);
    window.addEventListener("click", updateStatus);
    this.liveUpdate();
  },

  liveUpdate () {
    // console.log("liveUpdate");
    this.uninstall();
    const shouldRun = document.getElementById("status_channel");

    if (!!shouldRun) {
      this.online();
    }
  }

});
