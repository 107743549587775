// app/javascript/controllers/today_patients_new_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.fetchData()
      .then(data => {
        this.initializeChart(data.new_patients);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }

  initializeChart(data) {
    const labels = [];
    for (let hour = 0; hour < 24; hour += 3) {
      labels.push(this.formatAMPM(new Date(0, 0, 0, hour)));
    }

    const dataTodayNewPatients = {
      labels: labels,
      datasets: [{
        label: `New Patients`,
        data: data,
        backgroundColor: 'rgba(0, 123, 255, 0.5)',
      }]
    };

    const configTodayNewPatients = this.createConfig(dataTodayNewPatients);

    const ctxTodayNewPatients = this.element.getContext('2d');
    new Chart(ctxTodayNewPatients, configTodayNewPatients);
  }

  createConfig(data) {
    return {
      type: 'bar',
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
            ticks: {
              maxRotation: 0,
              minRotation: 0,
              font: {
                size: 8
              },
            },
            grid: {
              display: false
            }
          },
          y: {
            stacked: true,
            title: {
              text: "Distance",
              display: false
            }
          }
        },
        plugins: {
          legend: {
            display: false,
            position: 'top',
          }
        },
      }
    };
  }

  formatAMPM(date) {
    let hours = date.getHours();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    return hours + ' ' + ampm;
  }

  fetchData() {
    return fetch('/today_data')
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        return data;
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        throw error; // rethrow the error to propagate it to the next catch block
      });
  }
}
