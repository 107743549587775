import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="quickbooks"
export default class extends Controller {
  connect() {
    // Initialize the Intuit App Center SDK when the controller is connected
    // console.log('Quickbooks controller connected')
    this.setupIntuitSDK();
  }

  setupIntuitSDK() {
    // Load the Intuit App Center SDK script dynamically
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://appcenter.intuit.com/Content/IA/intuit.ipp.anywhere.js';
    script.async = true;
    script.onload = () => {
      // Initialize the Intuit SDK after the script has loaded
      this.initializeIntuitSDK();
    };

    // Append the script to the document's head
    document.head.appendChild(script);
  }

  initializeIntuitSDK() {
    // Configuration for the Intuit SDK
    const intuitConfig = {
      menuProxy: '/path/to/blue-dot',
      grantUrl: '/quickbooks/oauth_callback',
    };

    // Call the Intuit SDK setup function
    intuit.ipp.anywhere.setup(intuitConfig);

    // You can perform any additional setup or actions related to the Intuit SDK here
  }


  authenticate(event) {
    event.preventDefault();

    // Generate a callback URL for QuickBooks OAuth callback
    const callback = '/quickbooks_oauth_callback';

    // Make a request to your Rails controller to get the request token
    fetch('/quickbooks_request_token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ oauth_callback: callback }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Store the request token in the session
        sessionStorage.setItem('qb_request_token', data.token);

        // Redirect to the Intuit App Center for OAuth authorization
        window.location.href = `https://appcenter.intuit.com/Connect/Begin?oauth_token=${data.token}`;
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }
}
