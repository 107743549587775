import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="statistics"
export default class extends Controller {
  static targets = ["daily", "weekly", "monthly", "todayBtn", "weekBtn", "monthBtn", "dropdownStat"]

  connect() {
    // console.log("statistics stimulus connected")
  }

  updateStat(event) {
    const hideAllDivs = () => {
      daily.style.display = "none";
      weekly.style.display = "none";
      monthly.style.display = "none";
    };

    switch (event.target.id) {
      case "todayBtn":
        hideAllDivs();
        daily.style.display = "block";
        dropdownStat.innerText = "Today"
        break;
      case "weekBtn":
        hideAllDivs();
        weekly.style.display = "block";
        dropdownStat.innerText = "This week"
        break;
      case "monthBtn":
        hideAllDivs();
        monthly.style.display = "block";
        dropdownStat.innerText = "This month"
        break;
      default:
        hideAllDivs();
        break;
    }
  }
}
