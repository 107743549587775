import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

// Connects to data-controller="dynamic-load-booking"
export default class extends Controller {
  static targets = ["container"];

  connect() {
    // console.log("Dynamic Load Booking Controller connected");
    let link = this.containerTarget.baseURI;
    const url = new URL(link);
    const endPart = url.pathname; // This will give you "/bookings"
    console.log(endPart)
    this.startAutoRefresh();
  }

  disconnect() {
    // console.log("Dynamic Load Booking Controller disconnected");
    this.stopAutoRefresh();
  }

  async refresh() {
    // console.log("Refreshing bookings...");
    let link = this.containerTarget.baseURI;
    const url = new URL(link);
    const endPart = url.pathname; // This will give you "/bookings"
    // let endpath = link.pathname
    try {
      const response = await fetch(`${endPart}`, {
        headers: {
          Accept: "text/vnd.turbo-stream.html"
        }
      });
      // console.log("Response received:", response);

      if (response.ok) {
        const html = await response.text();
        this.containerTarget.innerHTML = html;
        console.log("Bookings updated");
      } else {
        console.log("Failed to refresh bookings. Response not OK:", response);
      }
    } catch (error) {
      console.error("Error refreshing bookings:", error);
    }
  }

  startAutoRefresh() {
    this.refreshInterval = setInterval(() => {
      this.refresh();
    }, 10000); // Auto-refresh every 10 seconds
    // console.log("Auto-refresh started");
  }

  stopAutoRefresh() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
      // console.log("Auto-refresh stopped");
    }
  }
}
