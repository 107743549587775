import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-class"
export default class extends Controller {
  static targets = ["checkbox", "icon"];

  connect() {
    this.toggleIcon(); // Initial check when the controller is connected
  }

  toggle() {
    this.toggleIcon();
  }

  toggleIcon() {
    this.checkboxTargets.forEach((checkbox, index) => {
      if (checkbox.checked) {
        this.iconTargets[index].classList.add("icon-white");
      } else {
        this.iconTargets[index].classList.remove("icon-white");
      }
    });
  }
}
