import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="statistics-heart-rate"
export default class extends Controller {
    connect() {
        const patientId = this.extractPatientIdFromUrl()
        this.fetchData(patientId)
            .then((data) => {
                this.initializeChart(data)
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
    }

    extractPatientIdFromUrl() {
        const urlParts = window.location.pathname.split('/')
        const patientIdIndex = urlParts.indexOf('patients') + 1
        return urlParts[patientIdIndex]
    }

    initializeChart(data) {
        const labels = data.map((consultation) =>
            this.formatDate(new Date(consultation.created_at))
        )
        const heartRateData = data.map(
            (consultation) => consultation.heart_rate
        )

        const dataTodayNewPatients = {
            labels: labels,
            datasets: [
                {
                    label: `BPM`,
                    data: heartRateData,
                    backgroundColor: 'rgba(0, 123, 255, 0.5)'
                }
            ]
        }

        const configTodayNewPatients = this.createConfig(dataTodayNewPatients)

        const ctxTodayNewPatients = this.element.getContext('2d')
        new Chart(ctxTodayNewPatients, configTodayNewPatients)
    }

    createConfig(data) {
        return {
            type: 'line',
            data: data,
            options: {
                responsive: true,
                maintainAspectRatio: true,
                scales: {
                    x: {
                        stacked: true,
                        title: {
                            text: 'Date & Time',
                            display: true
                        },
                        ticks: {
                            maxRotation: 0,
                            minRotation: 0,
                            font: {
                                size: 16
                            }
                        },
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        stacked: true,
                        title: {
                            text: 'BPM',
                            display: true
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                        position: 'top'
                    }
                },
                elements: {
                    point: {
                        radius: 5, // Increased dot size
                        hoverRadius: 7 // Increased hover dot size
                    }
                }
            }
        }
    }

    formatDate(date) {
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        }
        return date.toLocaleDateString('en-US', options)
    }
    fetchData(patientId) {
        return fetch(`/consultations_data/${patientId}`)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                return data
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
                throw error
            })
    }
}
