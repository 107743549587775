import { Application } from "@hotwired/stimulus"
import Sortable from 'stimulus-sortable'
import ReadMore from 'stimulus-read-more'
import PlacesAutocomplete from 'stimulus-places-autocomplete'

const application = Application.start()
application.register('sortable', Sortable)
application.register('read-more', ReadMore)
application.register('places-autocomplete', PlacesAutocomplete)

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

export { application }
