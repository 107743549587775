import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="home"
export default class extends Controller {
  static targets = ["staffDoctors", "staffHotlines", "staffDoctorsBtn", "staffHotlinesBtn"]

  connect() {
    // console.log("home stimulus connected")
  }

  updateStaff(event) {
    const remove = () => {
      staffDoctors.style.display = "none";
      staffHotlines.style.display = "none";
      staffDoctorsBtn.classList.remove('staff--active');
      staffHotlinesBtn.classList.remove('staff--active');
    };

    switch (event.target.id) {
      case "staffDoctorsBtn":
        remove();
        staffDoctors.style.display = "block";
        staffDoctorsBtn.classList.add('staff--active');
        break;
      case "staffHotlinesBtn":
        remove();
        staffHotlines.style.display = "block";
        staffHotlinesBtn.classList.add('staff--active');
        break;
      default:
        remove();
        break;
    }
  }
}
