import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static get targets() {
    return ["myInput", "patientVerification"];
  }

  static values = {
    patientId: Number // New value for patient ID
  }

  initialize() {
    this.fieldIds = {
      firstName: "patientFirstName",
      lastName: "patientLastName",
      mobileNumber: "patientMobileNumber",
      gender: "patientGender",
      dateOfBirth: "patientDateOfBirth",
      email: "patientEmail",
      age: "patientAge",
      address: "patientAddress",
      tagList: "patientTagList"
    }
  }

  async connect() {
    if (this.hasMyInputTarget) {
      // Initialize TomSelect if the <select> exists (new booking)
      const select = new TomSelect(this.myInputTarget, {
        sortField: 'text',
        allowEmptyOption: true
      });

      select.on("change", async (value, item) => {
        try {
          const patientData = await this.findPatient(value);
          this.updatePatientFields(this.fieldIds, patientData);
          this.updateAddress(this.myInputTarget.options[this.myInputTarget.selectedIndex]); // Pass the selected item
        } catch (error) {
          console.error("Error:", error);
        }
      });

      // Fetch and display patient details if a patient is already selected (e.g., in edit mode)
      const selectedPatientId = this.myInputTarget.value;
      if (selectedPatientId) {
        this.handlePatientChange(selectedPatientId);
      }
    } else if (this.hasPatientIdValue && this.patientIdValue > 0) {
      // If in edit mode, fetch and display patient details
      this.fetchAndDisplayPatientDetails(this.patientIdValue)
    }
  }

  // Handle changes in patient selection
  handlePatientChange = async (value) => {
    try {
      const patientData = await this.findPatient(value);
      this.updatePatientFields(this.fieldIds, patientData);
    } catch (error) {
      console.error("Error fetching patient data:", error);
    }
  };

  // Update patient-related fields based on selected patient data
  updatePatientFields(fieldIds, patientData) {
    Object.entries(fieldIds).forEach(([field, elementId]) => {
      const element = document.getElementById(elementId);
      if (element && patientData[field] !== undefined && patientData[field] !== null) {
        element.value = patientData[field];
      } else if (element) {
        element.value = ''; // Clear the field if data is missing
      }
    });
  }

  // Update address based on selected patient
  updateAddress(item) {
    // console.log("Selected item:", item);
    const address = item.dataset.address;
    const addressInput = document.getElementById("address-input");
    if (addressInput) {
      // console.log("Address:", address);
      addressInput.value = address;
    }
  }

  // Fetch detailed information about a specific patient
  async findPatient(patientId) {
    // console.log("patientId: ", patientId);
    if (!patientId || !this.hasPatientVerificationTarget || this.patientVerificationTarget.id !== "patient") {
      return {};
    }

    let url = `/patients/${patientId}/data_patient`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }
      const data = await response.json();
      if (data.error) {
        throw new Error(`Server error: ${data.error}`);
      }

      this.populatePatientDetails(data);
      return data;
    } catch (error) {
      console.error("Error fetching patient details:", error);
      return {};
    }
  }

  // Populate patient details in the DOM
  populatePatientDetails(data) {
    const patientDetailsDiv = document.getElementById('booking--patient-details');
    if (!patientDetailsDiv) {
      console.warn('Patient details container not found.');
      return;
    }
    patientDetailsDiv.innerHTML = '';

    const fieldsToUpdate = {
      firstName: 'First Name',
      lastName: 'Last Name',
      mobileNumber: 'Mobile Number',
      gender: 'Gender',
      dateOfBirth: 'Date Of Birth',
      email: 'Email',
      age: 'Age',
      address: 'Address',
      tagList: 'Tags'
    };

    Object.entries(fieldsToUpdate).forEach(([key, label]) => {
      const value = data[key] ?? 'No data';
      const detailElement = document.createElement('div');
      detailElement.innerHTML = `
        <p class="patient_details_label">${label}</p>
        <p class="patient_data_detail">${value}</p>
      `;
      patientDetailsDiv.appendChild(detailElement);
    });
  }

  // Fetch and display patient details when editing
  async fetchAndDisplayPatientDetails(patientId) {
    try {
      const patientData = await this.findPatient(patientId);
      this.populatePatientDetails(patientData);
    } catch (error) {
      console.error('Error fetching patient details:', error);
      const patientDetailsDiv = document.getElementById('booking--patient-details');
      if (patientDetailsDiv) {
        patientDetailsDiv.innerHTML = '<p class="error">Unable to fetch patient details. Please try again later.</p>';
      }
    }
  }
}
