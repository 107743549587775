// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails"
// import "@popperjs/core"

import "./controllers"
import "./channels"
import "bootstrap"

window.fireMapsLoadedEvent = function () {
  const evt = new Event("mapsLoaded");
  document.dispatchEvent(evt);

  // Ensure initAutocomplete is called only once after the script is loaded
  if (typeof window.initAutocomplete === "function") {
    window.initAutocomplete();
  }
};

// Function to initialize autocomplete
window.initAutocomplete = function () {
  const event = new Event('google-maps-callback', {
    bubbles: true,
    cancelable: true,
  });
  window.dispatchEvent(event);
};

// Attach the event listener to the `mapsLoaded` event
document.addEventListener('mapsLoaded', function () {
  // Initialize autocomplete here or handle the mapsLoaded event
  console.log('Maps API loaded and ready.');
});

// Add callback to the script dynamically
document.getElementById('google-maps-script').addEventListener('load', function () {
  // Ensure the callback is set only once
  window.fireMapsLoadedEvent();
});
