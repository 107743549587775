// app/javascript/controllers/month_visits_completed_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.fetchData()
    .then(data => {
      this.initializeChart(data, data.month_visits_completed);
    })
    .catch(error => {
      console.error("Error fetching data:", error);
    });
  }

  initializeChart(data, month_visits_completed) {
    // Sample data for the line chart
    const dataMonthVisitsCompleted = {
      labels: data.month_labels,
      datasets: [
        {
          label: 'Cashless',
          data: month_visits_completed[""],
          backgroundColor: ['#3CB7D1'],
          borderColor: ['#3CB7D1'],
          pointRadius: 2,
        },
        {
          label: 'Eagle Insurance',
          data: month_visits_completed.eagle_insurance,
          backgroundColor: ['#46B87A'],
          borderColor: ['#46B87A'],
          pointRadius: 2,
        },
        {
          label: 'SWAN Insurance',
          data: month_visits_completed.swan_insurance,
          backgroundColor: ['#00226A'],
          borderColor: ['#00226A'],
          pointRadius: 2,
        },
        {
          label: 'MUA Insurance',
          data: month_visits_completed.mua_insurance,
          backgroundColor: ['#FF671D'],
          borderColor: ['#FF671D'],
          pointRadius: 2,
        },
        {
          label: 'Jubilee Insurance',
          data: month_visits_completed.jubilee_insurance,
          backgroundColor: ['#FFD700'],
          borderColor: ['#FFD700'],
          pointRadius: 2,
        }
      ]
    };

    // Configuration for the line chart
    const configMonthVisitsCompleted = {
      type: 'line',
      data: dataMonthVisitsCompleted,
      options: {
        tension: 0.4,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            maxTicksLimit: 30,
            ticks: {
              maxRotation: 90,
              minRotation: 90,
              font: {
                size: 8
              },
              offset: 1
            },
            grid: {
              display: true
            }
          },
          y: {
            grid: {
              display: true
            }
          }
        },
        plugins: {
          legend: {
            display: false,
            position: 'top',
            labels: {
              boxWidth: 12,
            },
          }
        }
      },
    };

    const ctxMonthVisitsCompleted = this.element.getContext('2d');
    new Chart(ctxMonthVisitsCompleted, configMonthVisitsCompleted);
  }

  fetchData() {
    return fetch('/month_data')
      .then(response => response.json())
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        throw error;
      });
  }
}
