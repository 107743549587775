// app/javascript/controllers/search_form_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.inputTarget.addEventListener("input", this.search);
  }

  search = () => {
    this.element.addEventListener("ajax:beforeSend", (event) => {
      // Prevent the form from submitting in the default way.
      event.preventDefault();

      // You can add additional logic here if needed.

      // Submit the form via AJAX.
      // console.log("submitting");
      Rails.fire(this.element, "submit");
    });
  }
}
