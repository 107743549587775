import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["informantFields", "checkbox"];

  connect() {
    this.toggleFields();
  }

  toggleFields() {
    if (this.checkboxTarget.checked) {
      this.informantFieldsTarget.style.display = 'none';
    } else {
      this.informantFieldsTarget.style.display = 'block';
    }
  }

  toggle(event) {
    this.toggleFields();
  }
}
