import PlacesAutocomplete from "stimulus-places-autocomplete"

export default class extends PlacesAutocomplete {
  static targets = ["address", "lon", "lat"];
  connect() {
    super.connect()
    // console.log("Connected to the super places-autocomplete controller ")

    // The google.maps.places.Autocomplete instance.
    this.autocomplete
  }

  // You can override the `initAutocomplete` method here.
  initAutocomplete() {
    super.initAutocomplete()
  }

  // You can override the `placeChanged` method here.
  placeChanged() {
    super.placeChanged()


    const place = this.autocomplete.getPlace();
    if (place.geometry) {
      const lat = place.geometry.location.lat();
      const lon = place.geometry.location.lng();
      // console.log("lat", this.latTarget);
      // console.log("lon", this.lonTarget);
      this.latTarget.value = lat;
      this.lonTarget.value = lon;
    } else {
      console.log("Selected place does not have geometry information.");
    }
  }

  // You can set the Autocomplete options in this getter.
  get autocompleteOptions() {

    return {
      componentRestrictions: { country: "MU" },
      fields: ["address_components", "geometry", "icon", "name"],
      strictBounds: false,
    }
  }
}
