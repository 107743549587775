// app/javascript/controllers/today_visits_controller.js
import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js/auto';

let chart; // define chart variable outside of the class

export default class extends Controller {
  connect() {
    // Ensure that the chart is initialized only once
    if (!this.chartInitialized) {
      this.fetchData()
        .then(data => {
          this.initializeChart(data.visits);
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });

      this.chartInitialized = true;
    }
  }

  initializeChart(data) {
    const labels = [];
    for (let hour = 0; hour < 24; hour += 3) {
      labels.push(this.formatAMPM(new Date(0, 0, 0, hour)));
    }

    const dataTodayVisits = {
      labels: labels,
      datasets: [{
        label: 'Hourly Data',
        data: data,
        backgroundColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FFCD56', '#37A2EB'
        ],
        hoverBackgroundColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FFCD56', '#37A2EB'
        ]
      }]
    };

    const configTodayVisits = {
      type: 'doughnut',
      data: dataTodayVisits,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
          position: 'top',
        },
        title: {
          display: false,
          text: 'Hourly Distribution'
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        plugins: {
          legend: {
            display: false,
            position: 'top',
          }
        },
      }
    };

    const ctxTodayVisits = document.getElementById('chartTodayVisits').getContext('2d');

    // Destroy existing Chart.js instance if it exists
    if (chart) {
      chart.destroy();
    }

    chart = new Chart(ctxTodayVisits, configTodayVisits);
  }

  formatAMPM(date) {
    let hours = date.getHours();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    return hours + ' ' + ampm;
  }

  fetchData() {
    return fetch('/today_data')
      .then(response => response.json())
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        throw error;
      });
  }
}
