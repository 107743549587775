import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";

// Connects to data-controller="flatpickr"
export default class extends Controller {

  connect() {
    // Common Flatpickr options
    const commonOptions = {
      altInput: true,
      altFormat: "D, d M Y H:i",
      dateFormat: "Y-m-d H:i:S",
      enableTime: true,
      time_24hr: true,
      locale: {
        firstDayOfWeek: 1 // Start week on Monday
      },
      allowInput: true,
      position: "auto",
      // Additional options as needed
    };

    // Consent form variables
    const legalAdministrator = document.querySelector("#legalAdministrator")
    const assignedDoctor  = document.querySelector("#assignedDoctor")

    // Consultation form variables
    const scheduleDate = document.querySelectorAll(".scheduleDate")
    const patientSignature  = document.querySelectorAll(".patientSignature")
    const doctorSignature  = document.querySelectorAll(".doctorSignature")

    // Insurance form variables
    const doctorSignatureDate  = document.querySelector("#doctorSignatureDate")
    const memberSignatureDate  = document.querySelector("#memberSignatureDate")

    // Doctor database variable
    const doctorDateOfBirth  = document.querySelector("#doctorDateOfBirth")

    // Patient database variable
    const patientDateOfBirth  = document.querySelector("#patientDateOfBirth")

    // Staff database variable
    const staffsDateOfBirth  = document.querySelectorAll(".staffsDateOfBirth")

    // Bookings form date
    const dayOfAppointment = document.querySelectorAll(".dayOfAppointment")

    // Initialize Flatpickr with common options
    if (legalAdministrator && assignedDoctor) {
      flatpickr(legalAdministrator, commonOptions)
      flatpickr(assignedDoctor, commonOptions)
    }

    if (scheduleDate.length > 0 && patientSignature.length > 0 && doctorSignature.length > 0) {
      scheduleDate.forEach(element => flatpickr(element, commonOptions))
      patientSignature.forEach(element => flatpickr(element, commonOptions))
      doctorSignature.forEach(element => flatpickr(element, commonOptions))
    }

    if (doctorSignatureDate && memberSignatureDate) {
      flatpickr(doctorSignatureDate, commonOptions)
      flatpickr(memberSignatureDate, commonOptions)
    }

    if (doctorDateOfBirth) {
      flatpickr(doctorDateOfBirth, {
        dateFormat: "Y-m-d",
        altFormat: "F j, Y",
        maxDate: "today",
        ...commonOptions
      })
    }

    if (patientDateOfBirth) {
      flatpickr(patientDateOfBirth, {
        dateFormat: "Y-m-d",
        altFormat: "F j, Y",
        maxDate: "today",
        ...commonOptions
      })
    }

    if (staffsDateOfBirth.length > 0) {
      staffsDateOfBirth.forEach(element => flatpickr(element, {
        dateFormat: "Y-m-d",
        altFormat: "F j, Y",
        maxDate: "today",
        ...commonOptions
      }))
    }

    if (dayOfAppointment.length > 0) {
      dayOfAppointment.forEach(element => {
        const options = { ...commonOptions, enableTime: true };
        const isEdit = element.dataset.isEdit === "true";

        if (!isEdit && !element.value) {
          options.defaultDate = this.getNearestHour();
        }

        flatpickr(element, options);
      })
    }

    // Initialize Flatpickr for attendance date input if present
    const attendanceDateInput = document.querySelector("#attendance_attendance")
    if (attendanceDateInput) {
      const defaultDate = document.querySelector("#selectedDate")?.value || new Date()
      flatpickr(attendanceDateInput, {
        altInput: true,
        altFormat: "D, d M Y H:i",
        dateFormat: "Y-m-d H:i:S",
        enableTime: true,
        time_24hr: true,
        defaultDate: defaultDate,
        // Additional options...
      });
    }
  }

  // Helper method to calculate the nearest upcoming hour
  getNearestHour() {
    const now = new Date();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const milliseconds = now.getMilliseconds();

    // If current time is exactly at the hour, keep it; else, round up
    if (minutes === 0 && seconds === 0 && milliseconds === 0) {
      return now;
    } else {
      now.setMinutes(0, 0, 0);
      now.setHours(now.getHours() + 1);
      return now;
    }
  }
}
