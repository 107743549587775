// app/javascript/controllers/today_cancellations_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.fetchData()
      .then(data => {
        this.initializeChart(data.cancellation);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }


  initializeChart(data) {
    // console.log(data);
    const labels = [];
    for (let hour = 0; hour < 24; hour += 3) {
      labels.push(this.formatAMPM(new Date(0, 0, 0, hour)));
    }

    const dataTodayCancellations = {
      labels: labels,
      datasets: [{
        label: 'TIme:',
        data: data,
        backgroundColor: 'rgba(0, 123, 255, 0.5)',
      }]
    };

    const configTodayCancellations = this.createConfig(dataTodayCancellations);

    const ctxTodayCancellations = this.element.getContext('2d');
    new Chart(ctxTodayCancellations, configTodayCancellations);
  }

  createConfig(data) {
    const doughnutLabel = {
      id: 'doughnutLabel',
      beforeDraw(chart, args, options) {
        const { ctx, chartArea: { top, width, height } } = chart;
        let sum = chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
        ctx.save();

        ctx.font = `${options.fontSize}px Arial`;
        ctx.textAlign = 'center';
        ctx.fillStyle = options.fontColor;
        ctx.textBaseline = 'middle';
        ctx.fillText(`${sum}`, width / 2, height / 2 + top + options.fontSize / 20);
        ctx.restore();
      }
    };

    return {
      type: 'doughnut',
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        plugins: {
          legend: {
          display: false
          },
          doughnutLabel: {
            fontSize: 40,
            fontColor: '#263238'
          }
        }
      },
      plugins: [doughnutLabel]
    };
  }

  formatAMPM(date) {
    let hours = date.getHours();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    return hours + ' ' + ampm;
  }

  fetchData() {
    return fetch('/today_data')
      .then(response => response.json())
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        throw error;
      });
  }

}
