// app/javascript/controllers/month_cancellations_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.fetchData()
    .then(data => {
      this.initializeChart(data);
    })
    .catch(error => {
      console.error("Error fetching data:", error);
    });
  }

  initializeChart(data) {
    // Sample data for the line chart
    const dataMonthCancellations = {
      labels: data.month_labels,
      datasets: [
        {
          label: 'Cancellations',
          data: data.cancellation,
          backgroundColor: ['#3CB7D1'],
          borderColor: ['#3CB7D1'],
          pointRadius: 2,
        }
      ]
    };

    // Configuration for the line chart
    const configMonthCancellations = {
      type: 'line',
      data: dataMonthCancellations,
      options: {
        tension: 0.4,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            maxTicksLimit: 30,
            ticks: {
              maxRotation: 90,
              minRotation: 90,
              font: {
                size: 8
              },
              offset: 1
            },
            grid: {
              display: true
            }
          },
          y: {
            grid: {
              display: true
            }
          }
        },
        plugins: {
          legend: {
            display: false,
            position: 'top',
            labels: {
              boxWidth: 12,
            },
          }
        }
      },
    };

    const ctxMonthCancellations = this.element.getContext('2d');
    new Chart(ctxMonthCancellations, configMonthCancellations);
  }

  fetchData() {
    return fetch('/month_data')
      .then(response => response.json())
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        throw error;
      });
  }
}
