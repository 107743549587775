import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    console.log("Connected to validate-address controller");

    // Listen for the custom 'maps:loaded' event
    window.addEventListener('maps:loaded', this.initAutocomplete.bind(this));
  }

  initAutocomplete() {
    const input = this.inputTarget;
    const searchBox = new google.maps.places.SearchBox(input);

    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }

      const place = places[0];

      if (place.geometry && place.geometry.location) {
        console.log("Selected place:", place);
        this.handlePlaceSelection(place);
      }
    });
  }

  handlePlaceSelection(place) {
    this.inputTarget.value = place.formatted_address;
    this.inputTarget.setCustomValidity("");
    this.inputTarget.reportValidity();
  }
}
