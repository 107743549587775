import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "patientDetails", "patientDetailsNotRegistered", ];

  connect() {
    this.toggleFields();
  }

  toggleFields() {
    if (this.checkboxTarget.checked) {
      this.patientDetailsTarget.style.display = 'block';
      this.patientDetailsNotRegisteredTarget.style.display = 'none';
      this.clearFields(this.patientDetailsNotRegisteredTarget);
    } else {
      this.patientDetailsTarget.style.display = 'none';
      this.patientDetailsNotRegisteredTarget.style.display = 'block';
      this.clearFields(this.patientDetailsTarget);
    }
  }

  toggle() {
    this.toggleFields();
  }

  clearFields(container) {
    const inputs = container.querySelectorAll('input, select, textarea');
    inputs.forEach(input => {
      input.value = '';
    });
  }
}
