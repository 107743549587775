import { Controller } from '@hotwired/stimulus'

class CustomOverlay extends google.maps.OverlayView {
    constructor(position, content, map) {
        super()
        this.position = position
        this.content = content
        this.div = null
        this.setMap(map) // Reference to the map instance
    }

    onAdd() {
        this.div = document.createElement('div')
        this.div.style.position = 'absolute'
        this.div.style.backgroundColor = 'black'
        this.div.style.borderRadius = '4px'
        this.div.style.color = 'white'
        this.div.style.padding = '10px'
        this.div.style.fontSize = '12px'
        this.div.style.fontFamily = 'Arial, sans-serif'
        this.div.innerHTML = this.content

        this.div.classList.add("tooltips");

        const panes = this.getPanes()
        panes.floatPane.appendChild(this.div)
    }

    draw() {
        const overlayProjection = this.getProjection()
        const position = overlayProjection.fromLatLngToDivPixel(this.position)

        // Center horizontally
        const offsetX = -this.div.offsetWidth / 2 - 5 // slightly moving the square to the left
        this.div.style.left = position.x + offsetX + 'px'

        // Position above the marker
        const offsetY = this.div.offsetHeight + 35 // 35px for additional spacing
        this.div.style.top = position.y - offsetY + 'px'
    }

    onRemove() {
        if (this.div) {
            this.div.parentNode.removeChild(this.div)
            this.div = null
        }
    }
}

export default class extends Controller {
    static targets = ['coordinates']

    initialize() {
        this.markers = {} // Initialize an empty object to store markers
        this.customOverlays = {} // Initialize an empty object to store custom overlays
    }

    connect() {
        if (typeof google !== 'undefined') {
            this.renderMap()

            this.updateInterval = setInterval(() => {
                this.updateMarkers()
            }, 5000) // interval for 10 seconds between the fetch
        } else {
            console.log('Google Maps API is not loaded.')
        }
    }

    disconnect() {
        clearInterval(this.updateInterval)
    }

    async renderMap() {
        const lat = -20.253752868523762
        const lon = 57.57524372382422

        const coordinates = { lat: lat, lng: lon }

        this.map = new google.maps.Map(this.coordinatesTarget, {
            center: coordinates,
            zoom: 10.5,
            streetViewControl: false,
            mapTypeControl: false
        })

        // Draw district boundaries on the map
        this.drawDistrictBoundaries(this.map)

        let markers_data = await this.fetch_data()
        let markers = markers_data.markers

        markers.forEach((marker_data) => {
            if (
                (marker_data.doctorStatus != 'offline' ||
                    marker_data.doctorStatus != 'off_duty') &&
                Array.isArray(marker_data.patients) &&
                marker_data.patients.length >= 1
            ) {
                const coordinates = {
                    lat: parseFloat(marker_data.lat),
                    lng: parseFloat(marker_data.lng)
                }

                let iconUrl
                const doctorStatusResult =
                    marker_data.doctorStatus.toLowerCase()
                if (doctorStatusResult === 'offline' || doctorStatusResult === 'off_duty') {
                    iconUrl = '/assets/map-marker-offline.svg'
                } else {
                    iconUrl = '/assets/map-marker-online.svg'
                }

                const customIcon = {
                    url: iconUrl,
                    scaledSize: new google.maps.Size(60, 33.75)
                }

                let marker = new google.maps.Marker({
                    position: coordinates,
                    map: this.map,
                    icon: customIcon
                })

                // Store the marker using doctor's name as the key
                this.markers[marker_data.doctor] = marker

                // SECTION BELOW IS FOR THE OVERLAY CREATION
                const colors = {
                    assigned: '#A0D9E6',
                    acknowledge: '#AEEBB4',
                    rescheduled: '#D8A8E4',
                    reassigned: '#A0B8D3',
                    cancelled: '#CC6666',
                    in_transit: '#C1D8E6',
                    in_progress: '#E6C689',
                    extra_patient: '#F7A8B8',
                    extra_time: '#FAD4A2',
                    completed: '#A8D5A2',
                    call_ambulance: '#FFD966',
                    nearby: '#B3E0C9',
                    called_patient: '#F5B9A6',
                    eta_set: '#FFD8A8',
                    sms_sent: '#D0B8D8',
                    unreachable: '#C4C4C4'
                }

                const patientListItems = marker_data.patients
                    .map((patient) => {
                        const statusColor = colors[patient.status] || '#000000' // Default to black if status color is not defined
                        return `<li style="display: flex; gap: 4px; align-items: center;"><span style="color: ${statusColor}; font-size:18px;">■</span><span class="patient-name">${patient.patient_name}</span> - ${patient.bookingTime}</li>`
                    })
                    .join('')

                const contentString = `
                    <div style="color: white; position:relative;">
                      <ul style="list-style: none; padding: 0; margin: 0;">
                        <li><p style="font-size: 15px;">${marker_data.doctorFirstName} - ${this.formatStatus(marker_data.doctorStatus)}</p></li>
                        ${patientListItems || '<li>No current booking</li>'}
                      </ul>
                      <div class="arrow-down"></div>
                    </div>
                  `

                // Creating and using the custom overlay
                const newOverlay = new CustomOverlay(
                    marker.getPosition(),
                    contentString,
                    this.map
                )
                this.customOverlays[marker_data.doctor] = newOverlay
            }
        })
    }

    async fetch_data() {
        const url = '/fetch_markers'
        try {
            const response = await fetch(url)
            if (!response.ok) {
                throw new Error(
                    `Network response was not ok: ${response.status}`
                )
            }
            const data = await response.json()
            if (data.error) {
                throw new Error(`Server error: ${data.error}`)
            }
            // console.table(data)
            return data
        } catch (error) {
            console.error('Error:', error)
            throw error // Rethrow the error to indicate failure
        }
    }

    drawDistrictBoundaries(map) {
        const districtCoordinates = {
            north: [
                { lat: -20.038154660405645, lng: 57.68496220893801 },
                { lat: -20.116382691420796, lng: 57.60657209403735 },
                { lat: -20.122516571262654, lng: 57.58044205573713 },
                { lat: -20.12059975965392, lng: 57.49878568604895 }
            ],
            northEast: [
                { lat: -20.122516571262654, lng: 57.58044205573713 },
                { lat: -20.153185629902676, lng: 57.545726997165175 },
                { lat: -20.21473330935444, lng: 57.532680733310045 },
                { lat: -20.222143169580967, lng: 57.60065863444995 },
                { lat: -20.208289665792627, lng: 57.789142814883306 }
            ],
            east: [
                { lat: -20.222143169580967, lng: 57.60065863444995 },
                { lat: -20.304054183759483, lng: 57.598374103536294 },
                { lat: -20.30220479564131, lng: 57.650200334640594 },
                { lat: -20.323263830267752, lng: 57.70654322812745 },
                { lat: -20.30220479564131, lng: 57.77921739508874 },
                { lat: -20.323263830267752, lng: 57.70654322812745 },
                { lat: -20.30220479564131, lng: 57.650200334640594 }
            ],
            south: [
                { lat: -20.304054183759483, lng: 57.598374103536294 },
                { lat: -20.3047825860501, lng: 57.57183812936434 },
                { lat: -20.355078765284823, lng: 57.574000927025814 },
                { lat: -20.40630811394286, lng: 57.67399679094957 },
                { lat: -20.452324205818734, lng: 57.67545174119369 },
                { lat: -20.419889982092638, lng: 57.563472353145016 },
                { lat: -20.41786307919899, lng: 57.46138830352348 },
                { lat: -20.463664565351785, lng: 57.45489991053907 },
                { lat: -20.46447508818725, lng: 57.39866717134076 },
                { lat: -20.500944185109162, lng: 57.39434157601781 },
                { lat: -20.46447508818725, lng: 57.39866717134076 },
                { lat: -20.463664565351785, lng: 57.45489991053907 }
            ],
            west: [
                { lat: -20.41786307919899, lng: 57.46138830352348 },
                { lat: -20.239498899327344, lng: 57.4555365575498 },
                { lat: -20.239881970441914, lng: 57.46451875835796 },
                { lat: -20.165298899761744, lng: 57.46927452172269 }
            ],
            portLouis: [
                { lat: -20.239881970441914, lng: 57.46451875835796 },
                { lat: -20.239346267377297, lng: 57.48815263258235 },
                { lat: -20.21473330935444, lng: 57.532680733310045 }
            ]
        }

        // Iterate through the districtCoordinates to create a Polyline for each district
        Object.keys(districtCoordinates).forEach((district) => {
            const districtPath = districtCoordinates[district]

            const districtLine = new google.maps.Polyline({
                path: districtPath,
                geodesic: true,
                strokeColor: '#FF0000', // Red color for boundaries
                strokeOpacity: 0.8, // Slightly transparent
                strokeWeight: 1, // Thinner lines
                zIndex: 1 // Ensure boundaries are below markers
            })

            districtLine.setMap(map)
        })
    }

    // New method to update markers based on latest data
    async updateMarkers() {
        try {
            let markers_data = await this.fetch_data()
            let markers = markers_data.markers
            markers.forEach((marker_data) => {
                // IF STATEMENT TO KNOW IF THE DOCTOR IS OFFLINE AND HAS NO ACTIVE BOOKING FOR BOOKING OF THE DAY
                if (
                    (marker_data.doctorStatus === 'offline' ||
                        marker_data.doctorStatus === 'off_duty') &&
                    Array.isArray(marker_data.patients) &&
                    marker_data.patients.length === 0
                ) {
                    const doctorName = marker_data.doctor
                    if (this.markers[doctorName]) {
                        this.markers[doctorName].setMap(null) // Remove the marker from the map
                        delete this.markers[doctorName] // Delete the marker from the markers object
                    }
                    if (this.customOverlays[doctorName]) {
                        this.customOverlays[doctorName].setMap(null) // Remove the custom overlay from the map
                        delete this.customOverlays[doctorName] // Delete the overlay from the customOverlays object
                    }
                } else {
                    const doctorName = marker_data.doctor
                    const newLat = parseFloat(marker_data.lat)
                    const newLng = parseFloat(marker_data.lng)
                    const newPosition = { lat: newLat, lng: newLng } // Updated position

                    const colors = {
                        assigned: '#A0D9E6',
                        acknowledge: '#AEEBB4',
                        rescheduled: '#D8A8E4',
                        reassigned: '#A0B8D3',
                        cancelled: '#CC6666',
                        in_transit: '#C1D8E6',
                        in_progress: '#E6C689',
                        extra_patient: '#F7A8B8',
                        extra_time: '#FAD4A2',
                        completed: '#A8D5A2',
                        call_ambulance: '#FFD966',
                        nearby: '#B3E0C9',
                        called_patient: '#F5B9A6',
                        eta_set: '#FFD8A8',
                        sms_sent: '#D0B8D8',
                        unreachable: '#C4C4C4'
                    }

                    const patientListItems = marker_data.patients
                        .map((patient) => {
                            const statusColor =
                                colors[patient.status] || '#000000' // Default to black if status color is not defined
                            return `<li style="display: flex; gap: 4px; align-items: center;"><span style="color: ${statusColor}; font-size:18px;">■</span><span class="patient-name">${patient.patient_name}</span> - ${patient.bookingTime}</li>`
                        })
                        .join('')

                    const contentString = `
                        <div style="color: white; position:relative;">
                          <ul style="list-style: none; padding: 0; margin: 0;">
                            <li><p style="font-size: 15px;">${marker_data.doctorFirstName} - ${this.formatStatus(marker_data.doctorStatus)}</p></li>
                            <li>${patientListItems || 'No current booking'}</li>
                          </ul>
                          <div class="arrow-down"></div>
                        </div>
                        `

                    // Check if the marker already exists
                    if (this.markers[doctorName]) {
                        // Update marker position and icon
                        let iconUrl
                        const doctorStatusResult =
                            marker_data.doctorStatus.toLowerCase()
                        if (doctorStatusResult === 'offline' || doctorStatusResult === 'off_duty' ) {
                            iconUrl = '/assets/map-marker-offline.svg'
                        } else {
                            iconUrl = '/assets/map-marker-online.svg'
                        }
                        const customIcon = {
                            url: iconUrl,
                            scaledSize: new google.maps.Size(60, 33.75)
                        }

                        this.markers[doctorName].setPosition(newPosition)
                        this.markers[doctorName].setIcon(customIcon)

                        if (this.customOverlays[marker_data.doctor]) {
                            this.customOverlays[marker_data.doctor].setMap(null) // Remove the old overlay
                        }

                        const newOverlay = new CustomOverlay(
                            this.markers[marker_data.doctor].getPosition(),
                            contentString,
                            this.map
                        )
                        this.customOverlays[marker_data.doctor] = newOverlay
                    } else {
                        // Create a new marker if it doesn't exist
                        const newMarker = new google.maps.Marker({
                            position: newPosition,
                            map: this.map
                        })

                        let iconUrl
                        const doctorStatusResult =
                            marker_data.doctorStatus.toLowerCase()
                        if (doctorStatusResult === 'offline'|| doctorStatusResult === 'off_duty') {
                            iconUrl = '/assets/map-marker-offline.svg'
                        } else {
                            iconUrl = '/assets/map-marker-online.svg'
                        }
                        const customIcon = {
                            url: iconUrl,
                            scaledSize: new google.maps.Size(60, 33.75)
                        }
                        newMarker.setIcon(customIcon)

                        const newOverlay = new CustomOverlay(
                            newPosition,
                            contentString,
                            this.map
                        )

                        this.markers[doctorName] = newMarker
                        this.customOverlays[doctorName] = newOverlay
                    }
                }
            })
        } catch (error) {
            console.error('Error updating markers:', error)
        }
    }

    // Just to format the status
    formatStatus(status) {
        return status
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
    }
}
