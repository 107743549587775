import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sidebar-bookings"
export default class extends Controller {
  static targets = ["sidebarPatient", "sidebarDoctor", "labelPatient", "labelDoctor"]

  connect() {
    // console.log("bookings sidebar")
  }

  updateSidebar(event) {
    const hideAllDivs = () => {
      sidebarPatient.style.display = "none";
      sidebarDoctor.style.display = "none";
    };

    const hideAllUnderlines = () => {
      labelPatient.style.borderBottom = 0;
      labelDoctor.style.borderBottom = 0;
    };

    switch (event.target.id) {
      case "labelPatient":
        hideAllDivs();
        hideAllUnderlines();
        sidebarPatient.style.display = "block";
        labelPatient.style.borderBottom = "4px solid #3cb7d1";
        break;
      case "labelDoctor":
        hideAllDivs();
        hideAllUnderlines();
        sidebarDoctor.style.display = "block";
        labelDoctor.style.borderBottom = "4px solid #3cb7d1";
        break;
      default:
        hideAllDivs();
        hideAllUnderlines();
        break;
    }
  }
}
