import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["statusContainer"];

  // connect() {
  //   const usersStatus = JSON.parse(this.data.get("users"));
  //   this.displayStatus(usersStatus);
  //   // Subscribe to the user's channel
  //   this.subscription = consumer.subscriptions.create(
  //     { channel: "StatusChannel", user_id: this.data.get("userId") },
  //     {
  //       received: data => {
  //         this.updateUserStatus(data.status);
  //       }
  //     }
  //   );
  // }

  connect() {
    // console.log("Stimulus controller connected!");
  }

  updateStatus(users) {
    // Implement your logic to update the status in the Stimulus controller
    // console.log("Updating status in Stimulus controller:", users);
    // this.appendLines(users); // Assuming you have an appendLines method
  }


  // displayStatus(usersStatus) {
  //   usersStatus.forEach(user => {
  //     const pTag = document.createElement("p");
  //     pTag.textContent = `UserID:${user.id} - ${user.status}`;
  //     this.statusContainerTarget.appendChild(pTag);
  //   });
  // }

  updateUserStatus(status) {
    // Handle the updated status, e.g., update the UI
    // console.log("Updated status:", status);
  }
}
