// app/javascript/controllers/week_visits_completed_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.fetchData()
      .then(data => {
        this.initializeChart(data.week_visits_completed);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }

  initializeChart(data) {
    const noDataPlugin = this.createNoDataPlugin();

    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const todayIndex = new Date().getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
    const rearrangedLabels = [
      ...daysOfWeek.slice(todayIndex + 1),
      ...daysOfWeek.slice(0, todayIndex),
      'Today',
    ];
    const dataWeekVisitsCompleted = {
      labels: rearrangedLabels,
      datasets: [
        {
          label: 'Cashless',
          data: data[""],
          backgroundColor: ['#3CB7D1'],
        },
        {
          label: 'Eagle Insurance',
          data: data.eagle_insurance,
          backgroundColor: ['#46B87A'],
        },
        {
          label: 'SWAN Insurance',
          data: data.swan_insurance,
          backgroundColor: ['#00226A'],
        },
        {
          label: 'MUA Insurance',
          data: data.mua_insurance,
          backgroundColor: ['#FF671D'],
        },
        {
          label: 'Jubilee Insurance',
          data: data.jubilee_insurance,
          backgroundColor: ['#FFD700'],
        }
      ]
    };

    // Configuration for the bar chart
    const configWeekVisitsCompleted = this.createConfig(dataWeekVisitsCompleted, noDataPlugin);

    const ctxWeekVisitsCompleted = this.element.getContext('2d');
    new Chart(ctxWeekVisitsCompleted, configWeekVisitsCompleted);
  }

  createConfig(data, plugins) {
    return {
      type: 'bar',
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false
            }
          },
          y: {
            stacked: true
          }
        },
        plugins: {
          legend: {
            display: false,
            position: 'top',
            labels: {
              boxWidth: 12,
            },
          },
        },
      },
      plugins: [plugins]
    };
  }

  createNoDataPlugin() {
    return {
      id: 'noData',
      afterDatasetsDraw: ((chart, args, plugins) => {
        const { ctx, data, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;

        ctx.save();
        const difference = x.max - x.min + 1;
        const segment = width / difference;

        data.datasets[0].data.forEach((datapoint, index) => {
          const angle = Math.PI / 180;
          ctx.translate(0, 0);

          if (datapoint === null) {
            ctx.fillStyle = 'rgba(102, 102, 102, 1)';
            ctx.fillRect(x.getPixelForValue(index) - segment / 2, top, segment, height);

            ctx.rotate(90 * angle);
            ctx.font = 'bold 16px sans-serif';
            ctx.fillStyle = 'white';
            ctx.fillText('No Data', height / 2, -x.getPixelForValue(index));

            ctx.rotate(-90 * angle);
            ctx.restore();
          }
        })
      })
    };
  }

  fetchData() {
    return fetch('/week_data')
      .then(response => response.json())
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        throw error;
      });
  }
}
